export type * as _Whitelabel from "./_model";
import * as CONST from "./const";

// whitelabels
import app from "./flavors/app";
export const whitelabel$ = {
  CONST,
  flavors: {
    app,
  },
};
